<template>
  <v-container
    class="container-full-size"
  >
      <div>
        <calendar :client="client"></calendar>
      </div>
  </v-container>
</template>

<script>
  export default {
    name: 'client-calendar',
    props: ['parent', 'client'],
    components: {
      Calendar: () => import('@/views/dashboard/components/calendar/Calendar'),
    },
    data () {
      return {
        str: window.strings,
      }
    },
  }
</script>
